/* eslint-disable @typescript-eslint/no-unused-expressions */
import { culturalapi } from "utilities/Axios";

export const getAllData = async (plantId = 1) => {
  return culturalapi.get(
    `/Dashboard/GetPlantTrainUnitsEquipmentsTagsByPlant?plantId=${plantId}`
  );
};

export const getExpressionPastData = async (
  id: string,
  days: number,
  avgType: string
) => {
  let url = `/Dashboard/GetPastExpressionDataWithAvg?expressionId=${id}`;
  days ? (url += `&pastNumberOfDays=${days}`) : null;
  avgType ? (url += `&avgType=${avgType}`) : null;
  return culturalapi.get(url);
};

export const getTagsPastData = async (
  id: string,
  days: number,
  avgType: string
) => {
  let url = `/Dashboard/GetTagPastDataWithAvg?tagId=${id}`;
  days ? (url += `&pastNumberOfDays=${days}`) : null;
  avgType ? (url += `&avgType=${avgType}`) : null;
  return culturalapi.get(url);
};

export const getPinnedWidgets = async () => {
  return culturalapi.get(`/Dashboard/getPinnedWidgets`);
};

export const pinWidget = async (data: any) => {
  return culturalapi.post(`/Dashboard/pinWidget`, data);
};

export const getExpressionsValues = async (data: any) => {
  return culturalapi.post(`/Dashboard/GetExpressionValue`, data);
};

export const getSystemLogs = ({
  durType = 3,
  count = 10,
  action = null,
  module = null,
}) => {
  let url: any =
    `/Api/SystemLog/GetSystemLogsByDateTime` + `?durType=${durType}&count=${count}`;
  if (module != null) {
    url += `&module=${module}`;
  }
  if (action != null) {
    url += `&action=${action}`;
  }

  return culturalapi.get(url);
};
