
import {culturalapi} from "utilities/Axios";

export const UploadFiles = (data: any) => {
  return culturalapi.post("api/UnityMeta/UploadNoteFiles", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },

  });
};

export const createNote = (data: any) => {
  return culturalapi.post("api/UnityMeta/createnote", data, {
    headers: {
      "Content-Type": "application/json",
    },

  });
};

export const deleteNote = (id: any) => {
  return culturalapi.get("api/UnityMeta/DeleteNote" + `?id=${id}`);
};

export const getNote = (id: any) => {
  return culturalapi.get(`api/UnityMeta/GetNote?id=${id}`);
};

export const getAllNotes = () => {
  return culturalapi.get("api/UnityMeta/GetUnityNoteMeta");
};

export const downloadFile = (data: any) => {
  return culturalapi.post("api/UnityMeta/createnote", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },

  });
};

export const updateNote = (data: any) => {
  return culturalapi.post("api/UnityMeta/UpdateNote", data, {
    headers: {
      "Content-Type": "application/json",
    },

  });
};