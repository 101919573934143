import { LuFileEdit, LuTimerReset, LuTrash2 } from "react-icons/lu";
import { FiXSquare } from "react-icons/fi";
import { ActionName } from "types/common";
import { FiRefreshCw } from "react-icons/fi";
import { ReactComponent as AncknowledgeIcon } from "assets/AncknowledgeIcon.svg";
import { ReactComponent as CloseIcon } from "assets/CloseIcon.svg";

const tagsActionsConfig = (iconSize: any) => ({
  tag: [
    { name: 'edit', actionName: 'edit' as ActionName, icon: <LuFileEdit size={iconSize} />, danger: false },
    { name: 'reset', actionName: 'reset' as ActionName, icon: <LuTimerReset size={iconSize} />, danger: false },
    { name: 'delete', actionName: 'delete' as ActionName, icon: <LuTrash2 size={iconSize} />, danger: true }
  ],
  expression: [
    { name: 'edit', actionName: 'edit' as ActionName, icon: <LuFileEdit size={iconSize} />, danger: false },
    { name: 'delete', actionName: 'delete' as ActionName, icon: <LuTrash2 size={iconSize} />, danger: true }
  ],
  train: [
    { name: 'edit', actionName: 'edit' as ActionName, icon: <LuFileEdit size={iconSize} />, danger: false },
    { name: 'delete', actionName: 'delete' as ActionName, icon: <LuTrash2 size={iconSize} />, danger: true }
  ],
  unit: [
    { name: 'edit', actionName: 'edit' as ActionName, icon: <LuFileEdit size={iconSize} />, danger: false },
    { name: 'delete', actionName: 'delete' as ActionName, icon: <LuTrash2 size={iconSize} />, danger: true }
  ],
  equipment: [
    { name: 'edit', actionName: 'edit' as ActionName, icon: <LuFileEdit size={iconSize} />, danger: false },
    { name: 'delete', actionName: 'delete' as ActionName, icon: <LuTrash2 size={iconSize} />, danger: true }
  ],
  tickets: [
    { name: 'reject', actionName: 'reject' as ActionName, icon: <FiXSquare size={iconSize} className="hover:text-orange"/>, danger: true },
    { name: 'close', actionName: 'close' as ActionName, icon: <CloseIcon className="hover:text-orange 2xl:w-4 2xl:h-4 3xl:w-[18px] 3xl:h-[18px]"/>, danger: true },
    { name: 'delete', actionName: 'delete' as ActionName, icon: <LuTrash2 className="hover:text-red" size={iconSize} />, danger: true }
  ],
  alarms: [
    { name: 'Acknowledge', actionName: 'acknowledge' as ActionName, icon: <AncknowledgeIcon className="2xl:w-4 2xl:h-4 3xl:w-[18px] 3xl:h-[18px] hover:text-green" />, danger: false },
    { name: 'Delete', actionName: 'delete' as ActionName, icon: <LuTrash2 size={iconSize} className="mr-1 hover:text-red" />, danger: true },
  ],
  health: [
    { name: 'Refresh', actionName: 'refresh' as ActionName, icon: <FiRefreshCw size={iconSize} className="mt-1.5 cursor-pointer" color="cyan" />, danger: false },
  ]
});

export default tagsActionsConfig;
