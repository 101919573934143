import classNames from "utilities/ClassNames";
import { Box } from "@mui/system";
import useScreen from "hooks/useScreen";
import { getIconForAction } from "./config/IconMapping";
import { iconsColor, iconsSizes } from "./utils/constants";

const LogDetails = (props: any) => {
  const { isScaled } = useScreen();

  return (
    <Box width={"100%"} height={"100%"}>
      <div
        className={classNames(
          isScaled ? "text-3xl" : "text-2xl",
          "text-center border-b-2 border-[rgb(22,245,211)] py-2"
        )}
      >
        Details
      </div>
      <div
        className="py-1 px-2"
        style={{
          borderBottom: "2px rgb(22,245,211) solid",
        }}
      >
        <div
          className="row flex pt-0 justify-center items"
        >
          {props.LogData.action && (
            <>
              {getIconForAction(props.LogData.action, iconsColor, iconsSizes)}
            </>
          )}
          <div className="pl-2.5 pt-4">
            {props.LogData.module}
          </div>
        </div>
        <div className="col flex justify-between pb-2.5 w-full">
          <div className="flex-nowrap flex">
            <div>{props.LogData.at}</div>
          </div>
          <div
            className="col flex justify-right"
          >
            {props.LogData.by}
          </div>
        </div>
        <div
          className="col flex justify-center pb-5"
        >
          {props.LogData.desc}
        </div>
      </div>
      <pre
        className="p-2 xl:pt-10 leading-25 whitespace-pre-wrap break-word text-xs xl:text-sm"
      >
        {props.LogData.detail}
      </pre>
    </Box>
  );
};
export default LogDetails;
