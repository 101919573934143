import { createContext, useContext, useEffect, useState } from "react";

export const ScreenContext = createContext<any | null>(null);

export const ScreenProvider = ({ children }: { children: any }) => {
  const [isScaled, setisScaled] = useState<any>(null);

  useEffect(() => {
    if (window.devicePixelRatio === 1.25) {
      setisScaled(true);
    }
  }, []);

  return (
    <ScreenContext.Provider
      value={{
        isScaled,
        setisScaled,
      }}
    >
      {children}
    </ScreenContext.Provider>
  );
};

export default function useScreen() {
  return useContext(ScreenContext);
}
