import { getUserInfo } from "api/user";
import Loader from "components/shared/Loader";
import { createContext, useContext, useEffect, useState } from "react";
import { useAuth, hasAuthParams } from "react-oidc-context";

export const AppContext = createContext<any | null>(null);

const initialWidgets1 = [
  { id: "4390", tabs: [], position: 1 },
  { id: "8e65", tabs: [], position: 2 },
  { id: "33ec", tabs: [], position: 3 },
  { id: "ab5f", tabs: [], position: 4 },
  { id: "df7c", tabs: [], position: 5 },
];

const initialWidgets2 = [
  { id: "4370", tabs: [], position: 1 },
  { id: "8j65", tabs: [], position: 2 },
  { id: "33fc", tabs: [], position: 3 },
  { id: "ar5f", tabs: [], position: 4 },
  { id: "df3c", tabs: [], position: 5 },
];

export const AppProvider = ({ children }: { children: any }) => {
  const [isLoggedIn, setIsLoggedIn] = useState("loggedIn");
  const [user, setUser] = useState<any>(null);
  const [dateTime, setDateTime] = useState<any>(null);
  const [userRole, setuserRole] = useState<any>(null);

  const [dashboardWidgets, setDashboardWidgets] = useState<any>({
    selectedType: "init",
    widget1: initialWidgets1,
    widget2: initialWidgets2,
    widget3: [],
  });
  const [dashboardWidgetsLoading, setDashboardWidgetsLoading] = useState(true);


  useEffect(() => {
    localStorage.setItem("dateTime", dateTime);
  }, [dateTime]);

  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  useEffect(() => {
    (async () => {
      if (auth.isAuthenticated) {
        const response: any = await getUserInfo();
        if (response.status === 200) {
          setUser(response.data);
          setuserRole(response.data.role);
        }
      }
    })();
  }, [auth]);

  useEffect(() => {
    if (userRole) {
      if (userRole.includes("admin")) {
        setuserRole("admin");
      } else {
        setuserRole("user");
      }
    }
  }, [userRole]);

  // automatically sign-in
  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect({ state: window.location.href });
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  if (auth.isLoading)
    return (
      <div className="bg-black fixed inset-0 grid place-content-center">
        <Loader />
      </div>
    );

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }
  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }
  if (!auth.isAuthenticated) {
    return <div>Unable to log in</div>;
  }

  if (auth.isAuthenticated) {
    return (
      <AppContext.Provider
        value={{
          isLoggedIn,
          setIsLoggedIn,
          user,
          setUser,
          dateTime,
          setDateTime,
          userRole,
          setuserRole,
          dashboardWidgets,
          setDashboardWidgets,
          dashboardWidgetsLoading,
        }}
      >
        {children}
      </AppContext.Provider>
    );
  }
  // auth.signinRedirect();
  // auth.signinRedirect();
  return <button onClick={() => void auth.signinRedirect()}>Log in</button>;
};

export default function useApp() {
  return useContext(AppContext);
}
