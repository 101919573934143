import logo from "assets/logo.svg";
import { useState } from "react";
import { FiArrowLeft, FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import SideMenu from "./Sidemenu/SideMenu";
import UserMenu from "./UserMenu";
import DateTime from "./DateTime/DateTime";
import Info from "./Info";
import classNames from "utilities/ClassNames";
import useApp from "hooks/useApp";

export default function Header() {
  const [showSideMenu, setShowMenu] = useState(false);
  const { userRole } = useApp();
  console.log(userRole);
  return (
    <div className="w-full bg-dark relative z-40">
      <div
        className={classNames(
          "lg:h-12 xl-2xl:h-14 2xl:h-16",
          "mx-auto flex items-center px-3"
        )}
      >
        <div className="relative z-50 flex items-center gap-5">
          <div
            onClick={() => setShowMenu(!showSideMenu)}
            className="cursor-pointer duration-300"
          >
            {!showSideMenu && <FiMenu size={"30"} className="lg:w-[80%] xl-2xl:w-[90%] 2xl:w-full" />}
            {showSideMenu && <FiArrowLeft size={"30"} className="lg:w-[80%] xl-2xl:w-[90%] 2xl:w-full" />}
          </div>
          <Link to="/">
            <img
              src={logo}
              className={classNames("lg:h-6 xl-2xl:h-7 2xl:h-8")}
              alt="logo"
            />
          </Link>
        </div>
        <div className="ml-36">
          {userRole === 'admin' && (
            <div className="rounded bg-gradient-to-r from-green to-blue h-11 w-full items-center justify-center">
              <a href="/users" target="_blank" rel="noopener noreferrer">
                <button
                  className="text-center bg-gradient-to-r from-gray to-black h-100 rounded py-2 w-36  hover:opacity-70 text-white mt-0.5 mb-0.5 ml-0.5 mr-0.5 "
                >
                  Users
                </button>
              </a>
            </div>
          )}
        </div>
        <div className="flex-1" />
        <DateTime />
        <Info />
        <UserMenu />
        <SideMenu
          closeMenu={() => setShowMenu(false)}
          showSideMenu={showSideMenu}
        />
      </div>
    </div>
  );
}
